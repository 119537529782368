import React, { createRef, useEffect, useState } from "react"
import PropTypes from "prop-types"
import { isEmpty } from "lodash"
import DateInputAdmin from "components/date_input"
import { NumberField as TapestryNumberField } from "@planningcenter/tapestry-react"
import Icon from "components/church_center/cc_external_icon"
import sanitizeHtml from "sanitize-html"

const Checkbox = ({ errors, id, label, required, ...props }) => {
  const className = fieldErrorClass(errors)

  return (
    <div className={className}>
      <input type="checkbox" className="checkbox" id={id} {...props} />
      <label htmlFor={id} className="checkbox-label mb-4p mt-1">
        {label}
        {required && <Required />}
      </label>
    </div>
  )
}

function CheckboxProminent({ errors, id, label, required, ...props }) {
  const className = `${fieldErrorClass(errors)}`
  return (
    <div className={className}>
      <input type="checkbox" className="checkbox" id={id} {...props} />
      <label htmlFor={id} className="checkbox-label c-tint0 fw-500 mb-4p mt-1">
        {label}
        {required && <Required />}
      </label>
    </div>
  )
}

class CheckboxGroup extends React.Component {
  state = { other: "", selected: [], showOther: false }

  componentDidUpdate(_prevProps, prevState) {
    const { onChange } = this.props
    if (this.state !== prevState) {
      const { selected, showOther, other } = this.state
      if (showOther) {
        onChange({ selected, other })
      } else if (selected.length > 0) {
        onChange({ selected })
      } else {
        onChange({})
      }
    }
  }

  render() {
    const { id, hasOtherOption, options, className, errors } = this.props
    const { selected, showOther } = this.state

    return (
      <FieldGroup className={className}>
        {options
          .sort((a, b) => a.attributes.sequence - b.attributes.sequence)
          .map(o => (
            <Checkbox
              errors={errors}
              key={o.id}
              label={o.attributes.label}
              checked={selected.includes(o.id) ? "checked" : false}
              id={`checkbox_${o.id}`}
              onChange={e => {
                e.target.checked
                  ? this.setState({ selected: selected.concat([o.id]) })
                  : this.setState({
                      selected: selected.filter(s => s !== o.id),
                    })
              }}
            />
          ))}
        {hasOtherOption && (
          <Checkbox
            errors={errors}
            id={`other_${id}`}
            label="Other"
            checked={showOther}
            onChange={() => this.setState({ showOther: !showOther })}
          />
        )}
        {showOther && (
          <div className="form-field__input--full">
            <TextInput
              placeholder="Other"
              onChange={e => this.setState({ other: e.target.value })}
            />
          </div>
        )}
      </FieldGroup>
    )
  }
}

const ErrorMessages = ({ errors, className }) => {
  const hasErrors = errors && !isEmpty(errors)
  if (!hasErrors) return ""
  return Object.keys(errors).map(key => {
    const errorType = `${key.charAt(0).toUpperCase()}${key.slice(1)}`.replace(
      "_",
      " "
    )
    const errorMessage = errors[key]
    return (
      <div className={`c-ruby ${className}`} key={key}>
        {errorType} {errorMessage}
      </div>
    )
  })
}

const Field = ({
  id,
  hide,
  label,
  field_type: fieldType,
  required,
  children,
  errors,
  noLabel,
  help,
  fieldNote,
}) => {
  return hide ? null : (
    <div className="mt-3">
      {!noLabel && (
        <div className="d-f jc-sb">
          <label htmlFor={`${fieldType}_${id}`} className="f-1">
            {label}
            {required && <Required />}
          </label>
          {help && <div className="fs-4 c-tint2 f-r">{help}</div>}
        </div>
      )}
      {children}
      {fieldNote && <em className="fs-5 pt-4p">{fieldNote}</em>}
      <ErrorMessages errors={errors} className="mt-1" />
    </div>
  )
}

Field.propTypes = {
  id: PropTypes.string.isRequired,
  help: PropTypes.element,
}

const FieldGroup = ({ className = "", children }) => {
  return <div className={className}>{children}</div>
}

const Select = ({
  id,
  children,
  className,
  errors,
  hasOtherOption,
  onChange,
  ...props
}) => {
  const [other, setOther] = useState("")
  const [selected, setSelected] = useState()

  useEffect(() => {
    if (selected === "other") {
      onChange({ other })
    } else {
      onChange(selected)
    }
  }, [onChange, other, selected])

  return (
    <>
      <div className={`${className} ${fieldErrorClass(errors)} custom-select`}>
        <select
          className="select"
          id={id}
          value={selected}
          {...props}
          onChange={e => setSelected(e.target.value)}
        >
          {children}
          {hasOtherOption && <option value="other">Other</option>}
        </select>
      </div>
      {selected === "other" && (
        <div className="mt-1">
          <TextInput
            placeholder="Other"
            value={other}
            onChange={e => setOther(e.target.value)}
          />
        </div>
      )}
    </>
  )
}

const Submit = props => {
  return (
    <div className="my-4 ta-c">
      <input type="submit" className="btn" {...props} />
    </div>
  )
}

const TextArea = ({ errors, id, placeholder, ...props }) => {
  return (
    <div className={`${fieldErrorClass(errors)}`}>
      <textarea rows="5" id={id} placeholder={placeholder} {...props} />
    </div>
  )
}

const TextInput = ({ className = "", errors, id, placeholder, ...props }) => {
  return (
    <div
      className={`
        ${fieldErrorClass(errors)}
        ${className}
      `}
    >
      <input type="text" id={id} placeholder={placeholder} {...props} />
    </div>
  )
}

const NumberInput = props => {
  const { errors } = props
  return (
    <div className={`${fieldErrorClass(errors)}`}>
      <TapestryNumberField {...props} />
    </div>
  )
}

const DateInput = props => {
  const { errors } = props
  return (
    <div className={`date-field ${fieldErrorClass(errors)}`}>
      <span className="date-field__icon ml-1 mt-1" style={{ zIndex: "1" }}>
        <Icon symbol="cco#calendar-outline" title="Date" />
      </span>
      <DateInputAdmin {...props} />
    </div>
  )
}

const FileInput = ({
  id,
  blockList,
  multiFileInput,
  onAddFile,
  onRemoveFile,
  onUploadErrors,
  maxTotalFileSize,
  totalFileSizeExceedsLimit,
}) => {
  const [filesSelected, setFilesSelected] = useState([])

  const fileInput = createRef()

  const isOversized = file => {
    return file.size > 1024 * 1024 * 10
  }

  const exceedsNumberLimit = files => {
    return [...filesSelected, ...files].length > 5
  }

  const isUnsupportedExtension = file => {
    const extension = file.name.split(".").pop().toLowerCase()
    return blockList.indexOf(extension) !== -1
  }

  const atFileCapacity = () => {
    return filesSelected.length === 5
  }

  const handleFileChange = ({ id }) => {
    const files = Object.values(fileInput.current.files).filter(
      file => !filesSelected.find(att => att.name === file.name)
    )
    if (files.length === 0 || atFileCapacity()) {
      return
    }

    if (files.find(file => isOversized(file))) {
      onUploadErrors({ attachments: "size cannot exceed 10 MB." })
      return
    }

    if (files.find(file => isUnsupportedExtension(file))) {
      onUploadErrors({
        attachments: `type is not supported.`,
      })
      return
    }

    if (exceedsNumberLimit(files)) {
      onUploadErrors({ attachments: "can only include 5 attachments." })
      return
    }

    const newFilesSelected = multiFileInput
      ? [...filesSelected, ...files]
      : [files[0]]

    setFilesSelected(newFilesSelected)

    onAddFile({ form_field_id: id, files: newFilesSelected })
  }

  const handleFileRemove = ({ id, name }) => {
    fileInput.current.value = null
    const newFilesSelected = filesSelected.filter(
      fileSelected => fileSelected.name !== name
    )
    setFilesSelected(newFilesSelected)
    onRemoveFile(id, name)
  }

  const formatFileSize = (bytes, precision = 0) => {
    if (bytes < 1048576) {
      return (bytes / 1024).toFixed(precision) + " KB"
    } else {
      return (bytes / 1048576).toFixed(precision) + " MB"
    }
  }

  const renderFileInput = ({ id }) => {
    const initialBtnMessage = `Choose file${multiFileInput ? "s" : ""}…`
    const updateBtnMessage = multiFileInput ? "Add files…" : initialBtnMessage
    const noSelectionMsg = `No file${
      multiFileInput ? "s" : ""
    } currently selected`
    const selectionMsg = `${filesSelected.length} file${
      filesSelected.length > 1 ? "s" : ""
    } currently selected`
    const isDisabled = atFileCapacity() || totalFileSizeExceedsLimit

    return (
      <div className="file-uploader">
        <div className="d-f fw-w">
          <div className="mr-2 mb-1">
            <button
              type="button"
              className={`btn minor-btn secondary-btn ${
                isDisabled ? "disabled-btn" : null
              }`}
              style={{ minWidth: "7rem" }}
              htmlFor={`field${id}`}
              onClick={() => fileInput.current?.click()}
              disabled={isDisabled}
            >
              {filesSelected.length > 0 ? updateBtnMessage : initialBtnMessage}
            </button>
            <input
              type="file"
              name={`person[data][${id}]`}
              ref={fileInput}
              onChange={() => handleFileChange({ id })}
              tabIndex={-1}
              className="screen-reader-text"
              id={`field${id}`}
              multiple={multiFileInput}
              disabled={isDisabled}
            />
          </div>
          <div className="fs-4 pt-2p c-tint2">
            <p className="mb-0">
              {filesSelected.length > 0 ? selectionMsg : noSelectionMsg}
            </p>
          </div>
        </div>
        <div className="fs-5 c-tint3 mb-1 lh-1.25">
          {totalFileSizeExceedsLimit ? (
            <div className="d-f">
              <span className="fs-6">
                <Icon
                  className="c-citrine mr-4p ml-4p mt-2p"
                  symbol="general#exclamation-triangle"
                />
              </span>
              <span>
                {`You have exceeded the maximum total file size for attachments on this
            form (${formatFileSize(
              maxTotalFileSize
            )}). To upload additional files, please remove some first.`}
              </span>
            </div>
          ) : (
            <>
              {multiFileInput ? (
                <span>Add up to 5 files.</span>
              ) : (
                <span>Choose 1 file.</span>
              )}{" "}
              <span>Maximum file size 10 MB.</span>
            </>
          )}
        </div>
        <ul className="unstyled mb-1">
          {filesSelected.map(fileSelected => (
            <li className="d-f ai-c fs-4 pr-2 mb-1" key={fileSelected.name}>
              <div
                className="mr-1 lh-1 d-f ai-c jc-c c-tint4 o-h br-4p"
                style={{
                  width: 28,
                  height: 28,
                  flex: "0 0 28px",
                  border: "1px solid",
                }}
              >
                {["jpg", "jpeg", "gif", "png"].includes(
                  fileSelected.name.split(".").pop().toLowerCase()
                ) ? (
                  <img
                    width={28}
                    height={28}
                    style={{ objectFit: "cover" }}
                    src={window.URL.createObjectURL(fileSelected)}
                  />
                ) : (
                  <div className="fs-5 c-tint3 mt-2p">
                    <Icon symbol="general#outlined-generic-file" />
                  </div>
                )}
              </div>
              <div className="d-f fd-c overflow-text lh-1.25">
                <span className="overflow-text">{fileSelected.name}</span>
                <span className="fs-5 c-tint3">
                  {formatFileSize(fileSelected.size, 1)}
                </span>
              </div>
              <button
                type="button"
                className="btn minor-compact-btn destroy-btn ml-1 mb-2"
                style={{ marginBottom: 14 }}
                title={`Remove ${fileSelected.name}`}
                aria-label={`Remove ${fileSelected.name}`}
                onClick={() =>
                  handleFileRemove({ id, name: fileSelected.name })
                }
              >
                <Icon symbol="general#x" title="Remove" />
              </button>
            </li>
          ))}
        </ul>
      </div>
    )
  }

  return <div>{renderFileInput({ id })}</div>
}

const SectionHeader = ({ description, label }) => {
  return (
    <div className="section-header pt-4 pb-1">
      <h2 className="h2">{label}</h2>
      {description && (
        <span
          className="fs-4 c-tint0"
          dangerouslySetInnerHTML={{
            __html: sanitizeHtml(description).replace(/\n/g, "<br />"),
          }}
        ></span>
      )}
    </div>
  )
}

const Description = ({ description }) => {
  return (
    description && (
      <p
        className="fs-4 c-tint2 mb-1 mt-4p lh-tight p-r b-8p"
        dangerouslySetInnerHTML={{
          __html: sanitizeHtml(description).replace(/\n/g, "<br />"),
        }}
      ></p>
    )
  )
}

const Required = () => {
  return <span className="fs-3 c-ruby"> * </span>
}

const fieldErrorClass = (errors = {}) => {
  const hasErrors = !isEmpty(errors)

  return hasErrors ? "form-field--error" : ""
}

export {
  Checkbox,
  CheckboxProminent,
  CheckboxGroup,
  DateInput,
  Description,
  ErrorMessages,
  Field,
  FileInput,
  FieldGroup,
  NumberInput,
  Required,
  SectionHeader,
  Select,
  Submit,
  TextArea,
  TextInput,
}
