/** @jsx jsx */
import { useEffect, useState } from "react"
import { css, jsx } from "@emotion/react"
import {
  Combobox,
  ComboboxInput,
  ComboboxList,
  ComboboxOption,
  ComboboxPopover,
} from "@reach/combobox"
import { useDebounce } from "hooks/use_debounce"
import { sessionApiClient } from "@planningcenter/cc-api-client"

export function AutocompleteAddressInput({
  address,
  updateAttribute,
  onAutocompleteSelect,
}) {
  const [autocompletedAddresses, setAutocompletedAddresses] = useState([])
  const [searchTerm, setSearchTerm] = useState("")
  const debouncedSearchTerm = useDebounce(searchTerm, 300)

  useEffect(() => {
    if (!debouncedSearchTerm) return

    sessionApiClient
      .post(`/people/v2/address_autocomplete`, {
        data: {
          attributes: {
            query: debouncedSearchTerm,
          },
        },
      })
      .then(({ data }) => {
        const autocompletedAddresses = data.map(
          ({ attributes: { text, place_identifier } }) => {
            return {
              name: text,
              place_identifier,
            }
          }
        )
        setAutocompletedAddresses(autocompletedAddresses)
      })
  }, [debouncedSearchTerm])

  function onAutocompletionSelect(place_identifier) {
    setSearchTerm("")
    setAutocompletedAddresses([])

    sessionApiClient
      .post(`/people/v2/autocomplete_detail`, {
        data: {
          attributes: {
            place_identifier,
          },
        },
      })
      .then(({ data }) => {
        onAutocompleteSelect(data.attributes)
      })
  }

  const labelStyles = css`
    @media (max-width: 719px) {
      padding-bottom: 0px;
    }
  `

  return (
    <div className="f-1">
      <label htmlFor="street_line_1" css={labelStyles}>
        <span className="screen-reader-text">Street Address</span>
        <Combobox onSelect={onAutocompletionSelect}>
          <ComboboxInput
            style={{ borderLeft: "0px", borderRadius: "0px 4px 4px 0px" }}
            disabled={false}
            type="text"
            placeholder="Street Address"
            id="street_line_1"
            value={address}
            autocomplete={false}
            onChange={e => {
              const street = e.target.value
              setSearchTerm(street)

              updateAttribute(street)
            }}
          />
          <ComboboxPopover>
            <ComboboxList>
              {autocompletedAddresses.map(({ name, place_identifier }) => (
                <ComboboxOption
                  key={`address-${place_identifier}`}
                  value={place_identifier}
                >
                  {name}
                </ComboboxOption>
              ))}
            </ComboboxList>
          </ComboboxPopover>
        </Combobox>
      </label>
    </div>
  )
}
